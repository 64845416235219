import React from "react";

import {
  Authenticator,
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyA6VBrUC0kjTNvBOyeOutw3RQD23jpDlao",
  authDomain: "scango-ch.firebaseapp.com",
  databaseURL: "https://scango-ch-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scango-ch",
  storageBucket: "scango-ch.appspot.com",
  messagingSenderId: "842433807547",
  appId: "1:842433807547:web:eb2ccc8489c9e3a6cf159e",
  measurementId: "G-3ZQSQF40W6"
};

const locales = {
  "en-US": "English (United States)",
  "es-ES": "Spanish (Spain)",
  "de-DE": "German"
};

type Product = {
  orderDate: string;
  price: number;
  status: string;
  published: boolean;
  related_products: EntityReference[];
  main_image: string;
  tags: string[];
  description: string;
  categories: string[];
  publisher: {
    name: string;
    external_id: string;
  },
  expires_on: Date
}

const productSchema = buildSchema<Product>({
  name: "Product",
  properties: {
    orderDate: {
      title: "Date",
      validation: { required: true },
      dataType: "string"
    },
    price: {
      title: "Price",
      validation: {
        required: true,
      },
      description: "Price with range validation",
      dataType: "number"
    },
    status: {
      title: "Status",
      validation: { required: true },
      dataType: "string",
      description: "Should this product be visible in the website",
      longDescription: "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      config: {
        enumValues: {
          private: "Private",
          public: "Public"
        }
      }
    },
    published: ({ values }) => buildProperty({
      title: "Published",
      dataType: "boolean",
      columnWidth: 100,
      disabled: (
          values.status === "public"
              ? false
              : {
                clearOnDisabled: true,
                disabledMessage: "Status must be public in order to enable this the published flag"
              }
      )
    }),
    related_products: {
      dataType: "array",
      title: "Related products",
      description: "Reference to self",
      of: {
        dataType: "reference",
        path: "products"
      }
    },
    main_image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
      title: "Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"]
        }
      }
    }),
    tags: {
      title: "Tags",
      description: "Example of generic array",
      validation: { required: true },
      dataType: "array",
      of: {
        dataType: "string"
      }
    },
    description: {
      title: "Description",
      description: "Not mandatory but it'd be awesome if you filled this up",
      longDescription: "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      dataType: "string",
      columnWidth: 300
    },
    categories: {
      title: "Categories",
      validation: { required: true },
      dataType: "array",
      of: {
        dataType: "string",
        config: {
          enumValues: {
            electronics: "Electronics",
            books: "Books",
            furniture: "Furniture",
            clothing: "Clothing",
            food: "Food"
          }
        }
      }
    },
    publisher: {
      title: "Publisher",
      description: "This is an example of a map property",
      dataType: "map",
      properties: {
        name: {
          title: "Name",
          dataType: "string"
        },
        external_id: {
          title: "External id",
          dataType: "string"
        }
      }
    },
    expires_on: {
      title: "Expires on",
      dataType: "timestamp"
    }
  }
});

type Instance = {
  name: string;
  company: string;
  contactPerson: string;
  email: string;
  phone: string;
  website: string;
  backgroundImage: string;
}


const instanceSchema = buildSchema<Instance>({
  customId: locales,
  name: "Instanzen",
  properties: {
    company: {
      title: "Unternehmen",
      validation: { required: true },
      dataType: "string"
    },
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string"
    },
    contactPerson: {
      title: "Kontaktperson",
      dataType: "string"
    },
    email: {
      title: "E-Mail",
      dataType: "string"
    },
    phone: {
      title: "Telefon",
      dataType: "string"
    },
    website: {
      title: "Webseite",
      dataType: "string"
    },
    backgroundImage: {
      title: "Name",
      dataType: "string"
    },
  }
});

type Admins = {
  name: string;
  email: string;
}


const adminsSchema = buildSchema<Admins>({
  customId: locales,
  name: "Administratoren",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string"
    },

    email: {
      title: "E-Mail",
      dataType: "string"
    },
  }
});

type Devices = {
  comment: string;
  instanz: string;
  store: string;
  type: string;
}


const devicesSchema = buildSchema<Devices>({
  customId: locales,
  name: "Geräte",
  properties: {
    type: {
      title: "Typ",
      validation: { required: true },
      dataType: "string"
    },
    instanz: {
      title: "Instanz",
      dataType: "string"
    },
    store: {
      title: "Store",
      dataType: "string"
    },
    comment: {
      title: "Kommentar",
      dataType: "string"
    },
  }
});

type Users = {
  admin: boolean;
  //instance: string[];
  mail: string;
}


const usersSchema = buildSchema<Users>({
  customId: locales,
  name: "Benutzer",
  properties: {
    admin: {
      title: "Administrator",
      validation: { required: true },
      dataType: "boolean"
    },

    mail: {
      title: "E-Mail",
      dataType: "string"
    },
  }
});

type Mail = {
  admin: boolean;
  instance: string[];
  mail: string;
}


const mailSchema = buildSchema<Mail>({
  customId: locales,
  name: "Geräte",
  properties: {
    admin: {
      title: "Typ",
      validation: { required: true },
      dataType: "boolean"
    },
    instance: {
      title: "Instanz",
      dataType: "array"
    },
    mail: {
      title: "E-Mail",
      dataType: "string"
    },
  }
});



export default function App() {

  const navigation: NavigationBuilder = async ({
                                                 user,
                                                 authController
                                               }: NavigationBuilderProps) => {

    return ({
      collections: [
        buildCollection({
          path: "instance",
          schema: instanceSchema,
          name: "Instanzen",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: false,
          }),
          subcollections: [
            buildCollection({
              name: "Produkte",
              path: "products",
              schema: productSchema,
              permissions: ({ authController }) => ({
                edit: false,
                create: false,
                // we have created the roles object in the navigation builder
                delete: authController.extra.roles.includes("admin")
              }),
            })
          ]
        }),
        buildCollection({
          path: "admins",
          schema: adminsSchema,
          name: "Administratoren",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          }),
        }),
        buildCollection({
          path: "devices",
          schema: devicesSchema,
          name: "Geräte",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          }),
        }),
        buildCollection({
          path: "users",
          schema: usersSchema,
          name: "Benutzer",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          }),
        }),
        buildCollection({
          path: "mail",
          schema: mailSchema,
          name: "Mails",
          permissions: ({ authController }) => ({
            edit: false,
            create: false,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          }),
        }),
      ],

    });
  };

  const myAuthenticator: Authenticator = async ({ user, authController }) => {
    console.log("Allowing access to", user?.email);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: ["admin"]
    });
    authController.setExtra(sampleUserData);
    return true;
  };

  return <FirebaseCMSApp
      name={"scango.ch Backoffice"}
      authentication={myAuthenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
  />;
}